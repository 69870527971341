import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import logoFooter from "../../assets/svg/logo_footer.png";
import DownloadAppFooter from "../DownloadAppFooter";
import "./styles.css";

const facebook = process.env.REACT_APP_FACEBOOK;
const instagram = process.env.REACT_APP_INSTAGRAM;
const linkedin = process.env.REACT_APP_LINKEDIN;
const isWhitelabel = !!Number(process.env.REACT_APP_IS_WHITELABEL);

const MOBILE_WIDTH = 768;

function Footer() {
  const location = useLocation();
  const { t } = useTranslation();
  const [inCheckout, setInCheckout] = useState(false);
  const [isTablet, setIsTablet] = useState(false);

  useEffect(() => {
    setInCheckout(window.location.pathname.match(/\/checkout\/.*/)?.length);

    const checkIsTablet = () => {
      const width = window.innerWidth;
      setIsTablet(
        (width >= MOBILE_WIDTH && width <= 1024) || width < MOBILE_WIDTH
      );
    };

    checkIsTablet();
    window.addEventListener("resize", checkIsTablet);

    return () => {
      window.removeEventListener("resize", checkIsTablet);
    };
  }, [location]);

  function pageFaqs() {
    localStorage.removeItem("TIPE_FAQ");
    window.location.href = "/faqs";
  }

  return (
    <footer id="footer" className={inCheckout ? "checkout-footer" : ""}>
      <Container>
        <Row className="d-flex justify-content-sm-between">
          <Col sm={6} md={2}>
            {/* <CreateEventButton /> */}
            <h2>{t("Footer.whoWeAre")}</h2>
            <ul>
              <li>
                <Link to="/sobre-nos" hidden>
                  {t("Footer.aboutUs")}
                </Link>
              </li>
              <li>
                <Link to="/politicas">{t("Footer.ourPolicies")}</Link>
              </li>
            </ul>
          </Col>
          <Col sm={6} md={2}>
            <h2 className="mt-0">{t("Footer.termsAndPolicies")}</h2>
            <ul>
              <li>
                <Link to="/termos">{t("Footer.termsOfUse")}</Link>
              </li>
              <li>
                <Link to="/privacidade">{t("Footer.privacyPolicy")}</Link>
              </li>
              <li>
                <Link to="/cookies">{t("Footer.cookiePolicy")}</Link>
              </li>
            </ul>
          </Col>
          <Col sm={6} md={2}>
            <h2 className="mt-0">{t("Footer.help")}</h2>
            <ul>
              <li>
                <Link to="/faqs" onClick={pageFaqs}>
                  {t("Footer.faq")}
                </Link>
              </li>
              {isWhitelabel && (
                <li>
                  <Link to="/fale-conosco">{t("Footer.contactUs")}</Link>
                </li>
              )}
            </ul>
          </Col>
          {!isWhitelabel && !isTablet && (
            <>
              <Col sm={6} md={3}>
                <DownloadAppFooter />
              </Col>
            </>
          )}
          <Col sm={12} md={2} className="text-md-right text-center ml-sm-3">
            <img id="footer-logo" alt="" src={logoFooter} />
            <Link to="/fale-conosco">
              <i className="far fa-envelope"></i>{" "}
              <b>{t("Footer.supportAndContact")}</b>
            </Link>
          </Col>
        </Row>
        {!isWhitelabel && isTablet && (
          <>
            <Col className="d-flex justify-content-sm-center pl-0 mt-4" sm={12}>
              <DownloadAppFooter />
            </Col>
          </>
        )}
        <hr />
        <Row>
          <Col md={6}>
            {process.env.REACT_APP_LEGAL_NAME &&
            process.env.REACT_APP_LEGAL_DOCUMENT ? (
              <p>
                {process.env.REACT_APP_LEGAL_NAME} -{" "}
                <strong>{process.env.REACT_APP_LEGAL_DOCUMENT}</strong>
                <br />
                {process.env.REACT_APP_NAME_EMPRESA}{" "}
                {t("Footer.allRightsReserved")}
                {process.env.REACT_APP_ZIG_TICKETS_SUFFIX && (
                  <>
                    {" "}
                    {t("Footer.companyGroup")}{" "}
                    <strong>
                      <a
                        style={{ display: "inline" }}
                        href="https://tktwave.com/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {" "}
                        Zig
                      </a>
                    </strong>
                    .
                  </>
                )}
              </p>
            ) : (
              <p className="footer-phrase">
                {t("Footer.madeWithLove")} {dayjs().format("YYYY")}
              </p>
            )}
          </Col>
          <Col
            md={6}
            className="d-flex align-items-center justify-content-md-end justify-content-center"
          >
            <div id="social-icons" className="d-flex flex-row m-0">
              {facebook && (
                <a href={facebook} rel="noopener noreferrer" target="_blank">
                  <i className="fab fa-facebook"></i>
                </a>
              )}
              {instagram && (
                <a href={instagram} rel="noopener noreferrer" target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
              )}
              {linkedin && (
                <a href={linkedin} rel="noopener noreferrer" target="_blank">
                  <i className="fab fa-linkedin"></i>
                </a>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
