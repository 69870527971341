/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useZendesk } from "../../../hooks/useZendesk";

export default function PrivacyTerms() {
  const { hide } = useZendesk();

  useEffect(() => hide(), []);

  return (
    <div className="container mt-4 mb-4">
      <div className="row terms">
        <h1>PRIVACY POLICY</h1>
        <p>
          Welcome to the TktWave platform! The following Privacy Policy is based
          on our principles and values. We take your privacy very seriously and
          therefore protect your personal data.
          <br />
          <br />
          This Privacy and Data Protection Policy describes our policies on
          information that may be associated with or allow the identification of
          a person (Personal Data) and information that does not relate to a
          person and/or cannot be used to identify a person ( Non-Personal Data)
          in relation to the website.
          <br />
          <br />
          As a condition for accessing and using the TktWave platform and its
          functionalities, you declare that you have read the rules in this
          document in full and carefully, being fully aware of and in accordance
          with them.
        </p>

        <h4>
          <strong>DEFINITIONS</strong>
        </h4>

        <p>
          a) Costumer: term used to designate people who purchase tickets for
          events on sale by the Event Producer.
          <br />
          b) Controller of the Personal Data: natural or legal person, public or
          private, who is responsible for decisions regarding the processing of
          personal data.
          <br />
          c) Cookies: files whose purpose is to identify a computer and obtain
          access data, such as navigated pages or links clicked, thus allowing
          to customize their navigation, according to the user's profile.
          <br />
          d) Personal Data: information related to an identified or identifiable
          natural person.
          <br />
          e) Non-Personal Data: any information that does not relate to a person
          and/or cannot be used to identify a person.
          <br />
          f) Personal Data Operator: natural or legal person, public or private,
          who processes personal data on behalf of the Controller.
          <br />
          g) Event Producer: term used to designate people (individuals or legal
          entities) who create their own events through the TktWave platform and
          disseminate them to Costumers.
          <br />
          h) Participants: term used to designate people who enjoy the tickets
          purchased by Costumers. If Costumer purchase tickets in their own
          name, they will also be Participants. On the other hand, if the
          Costumer purchases tickets for the benefit of third parties, these
          will only be considered Participants, not Costumer.
          <br />
          i) TktWave Platform: designates the technological platform available
          to the Event Producers and Venues, in order to sell tickets, create
          and enroll events, collection of contributions for events and
          management of participants, accessible at the electronic address
          https://www.tktwave.com
          <br />
          j) Processing of Personal Data: any operation carried out with
          personal data, such as those referring to the collection, production,
          reception, classification, use, access, reproduction, transmission,
          distribution, processing, archiving, storage, elimination, evaluation
          or control of information, modification, communication, transfer,
          diffusion or extraction.
          <br />
          k) User: term used to designate, when referred to together, Event
          Producer, Costumer and people who browse the TktWave platform.
        </p>

        <h4>
          <strong>1. Obtaining personal and non-personal information</strong>
        </h4>
        <p>
          1.1. When using the TktWave platform, some information will be
          collected, including personal data. TktWave will collect such
          information on its own behalf or on behalf of the Event Producer,
          which uses the TktWave platform to create, manage and publicize its
          own event. This distinction is important to differentiate the
          situations in which TktWave acts as controller or operator of personal
          data.
          <br />
          1.2. TktWave will collect personal data from all users whenever they
          voluntarily provide such information, for example, when browsing or
          using the TktWave platform or any of its functionalities, or when
          contacting TktWave. The personal data we may collect includes: name,
          date of birth, email address, mailing address, password, telephone
          number and contact preferences, among others necessary to achieve the
          purposes set out in this Policy.
          <br />
          1.3. In addition to the aforementioned information, the user may
          provide other types of information, such as payment data, geolocation
          information, behavior when using the application or its products, and
          information regarding the computing device used to browse or use the
          TktWave platform, such as: IP addresses, browser type and language,
          Internet service provider, referring and exit pages, operating system,
          date and time information, clickstream data, device manufacturer,
          carrier, model, networks WiFi and phone number.
          <br />
          1.4. TktWave may also obtain non-personal information and information
          about user activities on the TktWave platform. Such information may be
          aggregated and used to help TktWave provide information to the Event
          Producers and understand which parts of the products and services
          attract the most interest from Costumers. Aggregated data is
          considered non-personal information for purposes of this Privacy
          Policy.
          <br />
          1.4.1. If TktWave combines non-personal information with personal
          information, the combined information will be treated as personal
          information as long as that information remains combined. 1.4.1.1. If
          the combined information is considered sensitive, that is, capable of
          revealing data related to racial or ethnic origin, religious
          conviction, political opinion, membership of a union or organization
          of a religious, philosophical or political nature, data referring to
          health or sex life, genetic or biometric data, the processing of this
          information will only be carried out with the consent of the holder.
          Such information will be stored securely and will not be shared with
          third parties, except after anonymization process.
          <br />
          1.5. TktWave may collect additional information from Event Producers,
          such as financial information, in order to guarantee certain payments
          and issuance of Invoice.
          <br />
          1.6. TktWave may collect additional information from Costumers, for
          example, when purchasing paid tickets, in which case the user will
          provide personal financial data (eg credit card number and expiration
          date, billing address, etc.).
          <br />
          1.6.1. Event Producers may, in their sole discretion, configure their
          event page to collect other personal information from Costumers. In
          these cases, TktWave, as operator of personal data, will not interfere
          in the data processing process carried out by the Event Producers, nor
          in what personal data will be collected or how it will be used by it.
          <br />
          1.6.2. Personal data collected on behalf of the Event Producers is
          available to them. In this way, the Event Producers, as controllers of
          personal data, are responsible for the treatment operations they carry
          out on this data, such as sharing, analysis, storage, among others.
          <br />
          1.6.3. TktWave has a specific document (“Data Processing Agreement”),
          which establishes its rights and obligations, as an Operator of
          personal data, regarding the processing operations carried out on
          behalf of the Event Producers.
          <br />
          1.6.4. Holders of personal data must exercise the rights provided for
          in item “7” of this document before the Event Producers, and TktWave
          may operationalize such requests in certain cases.
          <br />
          1.7. TktWave may also obtain information from its users from different
          sources, such as third-party websites, banking institutions, payment
          processors, and other companies or credit protection agencies. In this
          activity, the rules contained in the applicable legislation will be
          observed. 1.8. For the purposes of the provisions of Law No.
          13,709/2018 (“General Law for the Protection of Personal Data”),
          TktWave may act both as controller of personal data (e.g.: recommends)
        </p>
        <h4>
          <strong>2. Use of personal information</strong>
        </h4>
        <p>
          2.1. Personal data will be used in a manner consistent with the
          provisions of this Privacy Policy, with the aim of providing,
          developing and improving the services provided or products offered,
          including those to be created.
          <br />
          2.2. TktWave will use personal data in the following ways:
          <br />
          a) internal and managerial purposes, such as auditing, data analysis
          and research to improve products, services and communications with
          customers, and generating statistical analysis with respect to the use
          of our services, including consumption trends;
          <br />
          b) send notices and important notifications, such as announcements
          about purchases, changes in terms, conditions and policies. As this
          information is important for interacting with TktWave, the user cannot
          choose not to receive this type of communication, since it is inherent
          to the use of the TktWave platform;
          <br />
          c) improve security and improve TktWave's services and offers, as well
          as analyze and solve technical problems, in addition to identifying
          and curbing fraud in the use of the TktWave platform;
          <br />
          d) send advertising, as well as other promotional materials aimed at
          marketing our services and news to users, which includes digital
          campaigns (such as targeted marketing on social networks and
          notifications) and non-digital campaigns (such as broadcasting,
          billboards, pamphlets , between others). 2.2.1. TktWave will process
          personal data based on the following legal hypotheses: compliance with
          a legal obligation, execution of a contract, regular exercise of
          rights, fulfillment of legitimate interests. The legal basis for
          processing may vary depending on the activity carried out. 2.3.
          TktWave allows Organizers to use the TktWave platform and its
          functionalities to get in touch with Costumers and other users
          regarding their events. This tool works like a standard e-mail box,
          where the Event Producer can enter recipients automatically (through
          e-mail lists associated with their events hosted on the TktWave
          platform) or manually (through e-mail lists collected outside the
          TktWave platform) and then send them. Notices will be sent by the
          sender info@tktwave.com
          <br />
          2.3.1. The Event Producer is solely responsible for the content and
          sending of these communications, and it is also up to the Event
          Producer to cease such sending whenever requested by the recipient.
          The Event Producer is also responsible for processing the personal
          data of the recipients of these communications.
          <br />
          2.3.2. The Costumer is aware that unsubscribing from an Event
          Producer's email list does not prevent the receipt of communications
          sent by another Event Producer through the TktWave platform. To this
          end, Costumers must block the sender info@tktwave.com. in their email
          provider.
          <br />
          2.4. If TktWave uses any personal data in a manner different from
          those established in this Privacy Policy, the user will be previously
          informed about this new use, before or on the date on which the
          personal data is received.
        </p>

        <h4>
          <strong>3. Sharing of Personal Information </strong>
        </h4>

        <p>
          3.1. TktWave does not sell its users' personal data to third parties,
          but there are situations in which TktWave may disclose, transfer or
          share personal data with third parties, as provided in this document
          regulated by United States legislation.
          <br />
          3.2. TktWave may sell or buy other companies or assets. In case of
          sale, merger, reorganization, dissolution of the company or other
          similar corporate transaction, personal data may form part of the
          shared or transferred intangible assets.
          <br />
          3.2.1. TktWave may share personal data with companies of the same
          economic group, with the following purposes: (i) the development of
          new products and services; (ii) offering products and services that
          best meet the interests of users; (iii) generation of statistical and
          aggregated data about the use of products and services and user
          profiles.
          <br />
          3.3. TktWave may share the personal data of its users with contractors
          and service providers who process such data on behalf of TktWave, in
          order to perform certain functions related to TktWave's activities,
          such as, but not limited to, marketing agencies, providers database
          service providers, disaster recovery and backup service providers,
          email service providers, payment processors, and others.
          <br />
          3.4. TktWave may share its users' information with police or judicial
          authorities, competent public authorities or other third parties,
          inside and outside of the United Sates if required by applicable law,
          by judicial decision and by request of authorities, or if necessary to
          respond to legal proceedings or to participate in any litigation or
          disputes of any nature.
          <br />
          3.4.1. In these situations, TktWave will cooperate with the competent
          authorities to the extent that, at its discretion, it deems necessary
          and appropriate in relation to any investigation of illicit acts,
          infringements of industrial or intellectual property rights, or other
          activity that is illegal or that may expose TktWave or its users to
          any legal liability or to impose risks on them, except for cases of
          secrecy of information contained in the applicable legislation.
          <br />
          3.5. TktWave reserves the right to share information about its users
          with third parties when there are sufficient reasons to consider that
          a user's activity is suspicious, illegal or harmful to TktWave or
          third parties.
        </p>
        <h4>
          <strong>4. Storage of personal information</strong>
        </h4>
        <p>
          4.1. The personal information collected by TktWave will be stored in
          reliable cloud services, provided by partners that may be located in
          United States or in other countries, such as Brazil (BRA) and in
          countries of the European Union.
          <br />
          4.1.1. When contracting these services, TktWave will look for
          companies that employ a high level of security in the storage of your
          information, establishing contracts that do not violate the privacy
          definitions set forth in this Policy.
          <br />
          4.2. TktWave only stores user information for the period necessary for
          the purposes set out in the Terms of Use and in this Privacy Policy,
          always respecting the data retention period determined by applicable
          law.
          <br />
          4.2.1. If the user requests the deletion of his account, the personal
          information provided during the use of the TktWave platform will be
          anonymized or permanently deleted, unless such information is
          necessary for the fulfillment of a legal obligation by TktWave,
          fulfillment of legitimate interests, or for the regular exercise of
          rights in judicial, administrative or arbitration proceedings.
          <br />
          4.3. TktWave uses its best efforts to respect and protect users'
          personal information against loss, theft or any form of misuse, as
          well as against unauthorized access, disclosure, alteration and
          destruction.
          <br />
          4.3.1. TktWave will process personal data with a high degree of
          security, implementing the best practices in use in the industry for
          data protection, such as encryption techniques, monitoring and
          periodic security tests, firewall, among others. However, it is not
          possible to completely guarantee the non-occurrence of interceptions
          and violations of systems and databases, since the internet has its
          security structure in permanent improvement.
          <br />
          4.3.2. In the event of an information security incident that results
          in the destruction, loss, alteration, unauthorized access, or leakage
          of personal data, TktWave will immediately assess the risks to civil
          liberties and fundamental rights of the holders of personal data.
          Communication to data subjects or to the National Data Protection
          Authority will be carried out depending on the specific case, after
          assessing the risks mentioned.
        </p>
        <h4>
          <strong>5. International data transfer</strong>
        </h4>
        <p>
          5.1. TktWave may carry out international data transfers to other
          countries, such as the Brazil and European Union countries, in order
          to carry out some of the activities involved in the services provided
          to users, as well as to be able to obtain information that may
          contribute to the improvement and security of our services.
          <br />
          5.2. In the event of sharing with partners located in other countries,
          we contractually establish that the partner has a data protection and
          information security standard compatible with this privacy policy, so
          that data is always protected in these terms.
        </p>
        <h4>
          <strong>6. User rights</strong>
        </h4>
        <p>
          6.1. Users may request TktWave, in an easy and accessible way, through
          our web form on the Privacy Portal or by e-mail info@tktwave.com: (i)
          confirmation of the existence of processing of personal data; (ii)
          access to personal data; (iii) the correction of incomplete,
          inaccurate or outdated data; (iv) anonymization, blocking or deletion
          of unnecessary or excessive data; (v) data portability, within legal
          limits.
          <br />
          6.2. If a user initiates a request for the deletion of data owned by
          him, TktWave will be authorized to delete or anonymize the requester's
          personal data, even if this means removing his availability for the
          Event Producer on the TktWave platform. However, personal data may
          still be available in the Event Producer’s own databases if they were
          transmitted to the Event Producer before TktWave received or took
          action regarding deletion or anonymization.
        </p>
        <h4>
          <strong>
            7. Use of the TktWave platform by minors and incapable persons
          </strong>
        </h4>
        <p>
          7.1. When registering on the TktWave platform, the user expressly
          guarantees that he is fully capable, under the terms of current
          legislation, to exercise and enjoy all the Services. Furthermore,
          Users under 18 (eighteen) years of age must obtain the express consent
          of their parents, guardians or legal representatives to use the
          TktWave platform and its functionalities, in accordance with the
          provisions of the Terms of Use and Privacy Policy.
          <br />
          7.2. Parents, guardians or legal representatives will also be fully
          responsible in the case of access to the TktWave platform by children
          and adolescents, without proper prior authorization. It is up to them
          to be fully responsible for overseeing the activities and conduct of
          the respective minors under their guardianship, as well as being aware
          of the completeness of these Terms.
        </p>
        <h4>
          <strong>8. Cookies and other technologies</strong>
        </h4>
        <p>
          8.1. TktWave has its own policy on the use of cookies and other
          similar tracking technologies, called the “Cookies Policy”.
        </p>
        <h4>
          <strong>9. General Provisions</strong>
        </h4>
        <p>
          9.1. When browsing the TktWave platform and using its functionalities,
          users accept all the provisions of this Privacy Policy and other legal
          policies that are in force on the access date. Therefore, it is
          recommended that users stay up to date.
          <br />
          9.2. This Privacy Policy is subject to constant improvement and
          improvement. Thus, TktWave reserves the right to modify it at any
          time, according to the purpose of the TktWave platform, such as for
          adequacy and legal compliance with a provision of law or rule that has
          equivalent legal force, and it is up to users to verify it whenever
          access the TktWave platform.
          <br />
          9.3. Any tolerance for any violation of the terms and conditions
          contained in this domain will be considered mere liberality and will
          not be interpreted as novation, invoked precedent, waiver of rights,
          tacit amendment of contractual terms, acquired right or contractual
          amendment.
          <br />
          9.4. If any provision of this Policy is deemed inapplicable or without
          effect, the rest of the rules continue to be in force, without the
          need for a judicial measure that declares such assertion. The terms
          described herein will be interpreted according to United States
          legislation.
          <br />
          9.5. Communication between TktWave and the user must be carried out
          through the service channels indicated and made available on the
          TktWave platform, especially through the email address
          info@tktwave.com.
          <br />
          9.6. The jurisdiction of the Michigan State, is elected to resolve any
          doubts arising from this Agreement, excluding any other jurisdiction,
          however privileged it may be.
        </p>
      </div>
    </div>
  );
}
